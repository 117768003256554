import React from 'react'
import { Link } from 'gatsby'
import { useStaticQuery, graphql } from 'gatsby'
import { useEffect } from 'react'

export default function Header({ buttons = null, active = null, additionalClasses = null }) {

    const data = useStaticQuery(graphql`
            query SiteNavigation {
            allDataYaml(filter: {yamlId: {eq: "navigation"}}) {
                nodes {
                buttons {
                    id
                    label
                    url
                    subitems {
                    id
                    label
                    url
                    }
                }
                }
            }
            }
        `),
        segmentedData = data.allDataYaml.nodes[0].buttons.filter(obj => obj.id === buttons)[0],
        segmentedControlClassName = !additionalClasses ? 'segmented-control' : 'segmented-control ' + additionalClasses
            
    useEffect(() => {
        const   outerWrapper = document.getElementById("sc-outer-wrapper"),
                innerWrapper = document.getElementById("sc-inner-wrapper"),
                setWrapper = () => {
                    if(window.pageYOffset > y) {
                        innerWrapper.className = 'inner'
                        outerWrapper.className = 'active'
                    }
                    else{
                        innerWrapper.className = ''
                        outerWrapper.className = ''
                    }
                },
                setY = () => {
                    h = innerWrapper.offsetHeight
                    y = innerWrapper.offsetTop - h
                }
        
        let y = 0,
            h = 0

        setY()
        window.addEventListener('resize', setY)
        window.addEventListener('scroll', setWrapper)
        
    }, [])   

    return (
        <div id="sc-outer-wrapper">
            <div id="sc-inner-wrapper">
                <ul className={segmentedControlClassName}>
                    {
                        segmentedData.subitems.map((subitem, index) => (
                        <li
                        key={index + 'li'}
                        id={'nav-'+subitem.id}
                        className={subitem.id === active ? 'active' : ''}
                        >
                            <Link
                            key={index + 'link'}
                            to={subitem.url}
                            dangerouslySetInnerHTML={{ __html: subitem.label }}
                            />
                        </li>
                        ))
                    }
                </ul>
            </div>
        </div>
        
    )
}